<script>
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogFilterLink',

        props: {
            label: {
                type: String,
                required: true,
            },
            value: {
                type: Number | String,
                required: true,
            },
            urlParam: {
                type: String,
                required: true,
            },
            link: {
                type: Number | String,
            },
            count: Number,
            handleFetch: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            url() {
                return catalogStore.buildUrl(
                    this.urlParam,
                    this.value,
                    this.link,
                    !this.value || this.isSelected
                );
            },
        },

        methods: {
            fetch(event) {
                window.dataLayer.push({
                    event: 'clickOnCatalogFilter',
                });

                // Carregamento dinâmico se a API pushState estiver disponível
                if (typeof history.pushState !== 'function') return true;

                event.preventDefault();

                if (this.handleFetch) {
                    catalogStore.fetch(this.axios, this.url);
                } else {
                    this.$emit('fetch', this.url);
                }
            },
        },
    };
</script>