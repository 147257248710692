<template>
    <div class="pos-relative bg-default-1 rounded cursor-pointer px-1">
        <div v-if="bigger" class="d-flex">
            <i class="icon-cr icon-zoom-out mr-1" />
            Diminuir imagens
        </div>
        <div v-else class="d-flex">
            <i class="icon-cr icon-zoom-in mr-1" />
            Aumentar imagens
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CatalogImageOption',

        props: {
            bigger: Boolean,
        },
    };
</script>
