<template>
    <div id="catalog-mobile-filter" :class="{ open: show }" class="hidden-xs-up">
        <div id="catalog-mobile-filter-header" class="text-yanone" @click="$emit('hide')">
            <div class="section-header">
                <h2 class="my">
                    <i class="icon-cr icon-chevron-left"></i>
                    {{ $t('filters') }}
                </h2>
                <div class="ml-auto px">
                    <div v-if="loading" class="cr-spinner-small" />
                    <a
                        v-else-if="displaySelected"
                        :href="mainUrl"
                        class="text-underline text-uppercase text-primary-5"
                        @click="resetFilters"
                    >
                        {{ $t('toClear') }}
                    </a>
                </div>
            </div>
            <hr class="mx-0 my-0" />
        </div>

        <div id="catalog-mobile-filter-body" class="text-yanone">
            <div id="catalog-mobile-filter-content" :class="{ busy: loading }">
                <CatalogFilterGroupMobile
                    v-if="!$scope.IS_REGULAR_CUSTOMER"
                    :value="selected.inPhysicalStock"
                    class="mr my-1"
                    :label="$t('productionType')"
                >
                    <CatalogFilterGroupOption
                        v-for="stock in filters.stock_filter"
                        :key="stock.id"
                        :label="stock.name"
                        :value="stock.id"
                        url-param="inPhysicalStock"
                        id-prefix="mobile"
                    />
                </CatalogFilterGroupMobile>

                <CatalogFilterGroupMobile
                    v-if="displayFilterTypes"
                    :value="selectedTypeId"
                    :label="$t('type')"
                >
                    <CatalogFilterGroupOption
                        v-show="selected.type_id && selected.type_id < 500"
                        :link="selected.type_url"
                        :value="undefined"
                        :label="$t('all_m')"
                        url-param="tipo"
                        id-prefix="mobile"
                    />
                    <CatalogFilterGroupOption
                        v-for="type in filters.types"
                        v-if="type.count > 0"
                        :key="type.id"
                        :count="type.count"
                        :label="type.name"
                        :link="type.url"
                        :value="type.id"
                        url-param="tipo"
                        id-prefix="mobile"
                    />
                </CatalogFilterGroupMobile>

                <CatalogFilterGroupMobile
                    v-if="displayFilterModels"
                    :value="selected.model_url"
                    :label="$t('model')"
                >
                    <CatalogFilterGroupOption
                        :link="selected.model_url"
                        :value="undefined"
                        :label="$t('all_f')"
                        url-param="modelagem"
                        id-prefix="mobile"
                    />
                    <CatalogFilterGroupOption
                        v-for="model in filters.models"
                        v-show="model.count > 0"
                        :key="model.id"
                        :label="model.name"
                        :link="model.url"
                        :value="model.id"
                        url-param="modelagem"
                        id-prefix="mobile"
                    />
                </CatalogFilterGroupMobile>

                <CatalogFilterGroupMobile
                    v-if="displayFilterSizesAdult || displayFilterSizesChild || displayFilterSizes"
                    :value="selected.size"
                    :label="$t('size')"
                >
                    <ul class="catalog-mobile-filter-sizes d-flex flex-wrap">
                        <CatalogFilterGroupOptionSize
                            :handle-fetch="false"
                            :value="undefined"
                            :label="$t('all_m')"
                            url-param="tamanho"
                            id-prefix="mobile"
                            @fetch="$emit('filter-all-sizes', $event)"
                        />
                    </ul>
                    <div v-if="displayFilterSizesAdult">
                        <h4>{{ $t('adult') }}</h4>
                        <ul class="catalog-mobile-filter-sizes d-flex flex-wrap">
                            <CatalogFilterGroupOptionSize
                                v-for="size in filters.sizes_adult"
                                v-if="size.count > 0"
                                :key="size.name"
                                :handle-fetch="false"
                                :label="size.name"
                                :link="size.url"
                                :value="size.name"
                                url-param="tamanho"
                                id-prefix="mobile"
                                @fetch="$emit('filter-size', size, $event)"
                            />
                        </ul>
                    </div>
                    <div v-if="displayFilterSizesChild">
                        <h4>{{ $t('_product.kids') }}</h4>
                        <ul class="catalog-mobile-filter-sizes d-flex flex-wrap">
                            <CatalogFilterGroupOptionSize
                                v-for="size in filters.sizes_child"
                                v-if="size.count > 0"
                                :key="size.name"
                                :handle-fetch="false"
                                :label="size.name"
                                :link="size.url"
                                :value="size.name"
                                url-param="tamanho"
                                id-prefix="mobile"
                                @fetch="$emit('filter-size', size, $event)"
                            />
                        </ul>
                    </div>
                </CatalogFilterGroupMobile>

                <div v-if="displayInPhysicalStock" class="my-1 d-flex flex-align-center">
                    <div class="d-flex flex-align-center">
                        <div @click.stop.prevent="$emit('toggle-delivery-ready')">
                            <input
                                id="catalog-filter-delivery-ready-mobile"
                                :checked="deliveryReadySelected"
                                class="cr-checkbox"
                                type="checkbox"
                            />
                            <label for="catalog-filter-delivery-ready-mobile">
                                {{ $t('_catalog._filter.withDeliveryReady') }}
                                <div v-show="showDeliveryReadyWarning" class="text-warning-4">
                                    {{ $t('_catalog._filter.selectSize') }}
                                </div>
                            </label>
                        </div>
                        <span @click="$emit('delivery-ready-info')">
                            <i class="cursor-pointer icon-cr icon-question-sign"></i>
                        </span>
                    </div>
                </div>

                <CatalogFilterGroupMobile
                    v-if="displayFilterColors"
                    :value="selected.color"
                    label="Cor"
                >
                    <ul
                        v-if="!multipleFabricsSelected"
                        id="catalog-mobile-filter-colors"
                        class="d-flex flex-wrap"
                    >
                        <CatalogFilterGroupOptionColor
                            v-for="color in filters.colors"
                            v-if="color.id && color.id !== 14"
                            :key="color.id"
                            :hex="color.rgb"
                            :label="color.name"
                            :link="color.url"
                            :value="color.id"
                            url-param="cor"
                            id-prefix="mobile"
                        />
                    </ul>
                </CatalogFilterGroupMobile>

                <p v-if="(selected || {}).color_name">
                    <strong>{{ selected.color_name }}</strong>
                </p>

                <div
                    v-if="displayMulticolorFilter"
                    class="my-1 d-flex flex-align-center"
                    @click.stop.prevent="$emit('toggle-multiple-fabrics')"
                >
                    <div>
                        <input
                            id="catalog-filter-multiple-colors-mobile"
                            :checked="multipleFabricsSelected"
                            class="cr-checkbox"
                            type="checkbox"
                        />
                        <label for="catalog-filter-multiple-colors-mobile">
                            {{ $t('_catalog._filter.withMultipleColors') }}
                        </label>
                    </div>
                </div>

                <CatalogFilterGroupMobile
                    v-if="displayFilterPrices"
                    :value="selected.price"
                    :label="$t('price')"
                >
                    <CatalogFilterGroupOption
                        :link="selected.price"
                        :value="undefined"
                        :label="$t('all_m')"
                        url-param="preco"
                        id-prefix="mobile"
                    />
                    <CatalogFilterGroupOption
                        v-for="price in filters.prices"
                        v-if="price.count > 0"
                        :key="price.id"
                        :count="price.count"
                        :label="getPriceLabel(price)"
                        :link="price.url"
                        :value="price.id"
                        url-param="preco"
                        id-prefix="mobile"
                    />
                </CatalogFilterGroupMobile>

                <CatalogFilterGroupMobile
                    v-if="displayFilterCategories"
                    :label="$t(hasCollections ? 'category' : 'theme')"
                    :value="selected.category"
                >
                    <CatalogFilterGroupOption
                        :link="selected.category_url"
                        :value="undefined"
                        :label="$t('all_f')"
                        url-param="categoria"
                        id-prefix="mobile"
                    />

                    <div v-if="hasCollections">
                        <h4>Coleções Exclusivas</h4>
                        <CatalogFilterGroupOption
                            v-for="collection in filters.collections"
                            v-if="collection.count > 0"
                            :key="collection.id"
                            :count="collection.count"
                            :label="collection.name"
                            :link="collection.url"
                            :value="collection.id"
                            url-param="categoria"
                            id-prefix="mobile"
                        />
                    </div>

                    <div v-if="hasCategories">
                        <h4 v-if="hasCollections">Temas</h4>
                        <CatalogFilterGroupOption
                            v-for="category in filters.categories"
                            v-if="category.count > 0"
                            :key="category.id"
                            :count="category.count"
                            :label="category.name"
                            :link="category.url"
                            :value="category.id"
                            url-param="categoria"
                            id-prefix="mobile"
                        />
                    </div>
                </CatalogFilterGroupMobile>
            </div>
        </div>
    </div>
</template>

<script>
    import CatalogFilterGroupMobile from './CatalogFilterGroupMobile';
    import CatalogFilterGroupOption from './CatalogFilterGroupOption';
    import CatalogFilters from './CatalogFilters';
    import CatalogSortMobile from './CatalogSortMobile';
    import CatalogFilterGroupOptionColor from './CatalogFilterGroupOptionColor';
    import CatalogFilterGroupOptionSize from './CatalogFilterGroupOptionSize';
    import CatalogFilterGroupDesktop from './CatalogFilterGroupDesktop.vue';

    export default {
        name: 'CatalogFiltersMobile',

        components: {
            CatalogFilterGroupDesktop,
            CatalogFilterGroupOptionSize,
            CatalogFilterGroupOptionColor,
            CatalogSortMobile,
            CatalogFilterGroupOption,
            CatalogFilterGroupMobile,
        },

        extends: CatalogFilters,

        props: {
            show: Boolean,
        },
    };
</script>
