<template>
    <div
        v-if="product.promo_combo > 0 && showPromo"
        class="product-seal product-seal-primary"
        :class="{ 'text-medium': small }"
    >
        {{ isCombo ? 'Combo' : product.promo_name }}
    </div>

    <div
        v-else-if="showDiscount && product.discount && $scope.IS_REGULAR_CUSTOMER"
        class="product-seal product-seal-primary"
        :class="{ 'text-medium': small }"
    >
        {{ product.discount }}% OFF
    </div>

    <div
        v-else-if="product.is_new && !product.promo_combo"
        class="product-seal product-seal-new"
        :class="{ 'text-medium': small }"
    >
        {{
            [5, 6, 8, 10, 14, 18, 31, 32, 36, 38, 42].indexOf(product.type.id) !== -1
                ? 'Novo'
                : 'Nova'
        }}
    </div>
</template>

<script>
    export default {
        name: 'ProductListItemSeal',

        props: {
            product: {
                type: Object,
                required: true,
            },
            showDiscount: {
                type: Boolean,
                required: true,
            },
            showPromo: {
                type: Boolean,
                required: true,
            },
            small: Boolean,
        },

        computed: {
            isCombo() {
                return (
                    this.product.promo_instruction &&
                    this.product.promo_combo_unit_price &&
                    [25, 28].includes(this.product.promo_combo)
                );
            },
        },
    };
</script>