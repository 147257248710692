<template>
    <Paginator
        :allow-per-page-change="true"
        :pagination="products"
        @change-page="fetch($event.page, $event.perPage)"
    />
</template>

<script>
    import Paginator from '../common/Paginator';
    import { setCookie } from '../../common/common';
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogPagination',

        components: { Paginator },

        data() {
            return {
                storeState: catalogStore.state,
            };
        },

        computed: {
            products() {
                return this.storeState.products;
            },
        },

        methods: {
            getPage(page, perPage) {
                let url = new URL(window.location.href);

                if (page > 1) url.searchParams.set('page', page);
                else url.searchParams.delete('page');

                if (perPage != '36') url.searchParams.set('per_page', perPage);
                else url.searchParams.delete('per_page');

                setCookie('products_per_page', perPage);

                return url.toString();
            },

            fetch(pageNum, perPage) {
                // Carregamento dinâmico se a API pushState estiver disponível
                if (typeof history.pushState !== 'function') return true;

                dataLayer.push({
                    event: 'click-pagination',
                    'tag-event': 'click-pagination-normal',
                    'category-event': 'listagem',
                });

                let pagehref = this.getPage(pageNum, perPage);
                catalogStore.fetchState(this.axios, pagehref, pagehref);
            },
        },
    };
</script>
