<template>
    <li @click="fetch">
        <label
            :class="{ selected: isSelected }"
            :style="`background-color: #${hex}`"
            class="rounded"
        >
            {{ label }}
        </label>
    </li>
</template>

<script>
    import CatalogFilterGroupOption from './CatalogFilterGroupOption';

    export default {
        name: 'CatalogFilterGroupOptionSize',

        extends: CatalogFilterGroupOption,
    };
</script>
