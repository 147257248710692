import Vue from '../common/vue-common';
import Catalog from '../components/catalog/Catalog';
import RenderNotifier from '../components/common/RenderNotifier';
import shop from '../i18n/shop';
import catalog from '../i18n/catalog';
import general from '../i18n/general';
import locale from '../i18n/locale';
import product from '../i18n/product';

new Vue({
    el: '#catalog-vue',
    i18n: locale(general(), catalog(), product(), shop()),
    components: { catalog: Catalog, 'render-notifier': RenderNotifier },
});
