<template>
    <div v-if="total > 0" class="text-secondary mr-2">
        <span v-if="pagination.total_pages > 1">
            {{ first }} - {{ last }} {{ $t('of').toLowerCase() }}
        </span>
        {{ $tc('_catalog._filter.resultsX', total) }}
        <span v-if="showSortLabel" class="hidden-xs-down">
            {{ $t('_catalog._sort.sortedBy').toLowerCase() }}
        </span>
    </div>
</template>

<script>
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogPaginationInfo',

        props: {
            showSortLabel: Boolean,
        },

        data() {
            return {
                storeState: catalogStore.state,
                emptyPagination: {
                    totalHits: 0,
                    hits: [],
                    per_page: 0,
                    current_page: 0,
                },
            };
        },

        computed: {
            products() {
                return this.storeState.products;
            },

            pagination() {
                if (!this.products) return this.emptyPagination;

                return this.products;
            },

            first() {
                return 1 + (this.pagination.current_page - 1) * this.pagination.per_page;
            },

            last() {
                return (
                    (this.pagination.current_page - 1) * this.pagination.per_page +
                    this.pagination.hits.length
                );
            },

            total() {
                return this.pagination.totalHits;
            },
        },
    };
</script>
