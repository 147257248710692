<template>
    <div>
        <CatalogFiltersMobile
            :delivery-ready-selected="deliveryReadySelected"
            :multiple-fabrics-selected="multipleFabricsSelected"
            :show="showMobile"
            :show-delivery-ready-warning="showDeliveryReadyWarning"
            @hide="$emit('hide-mobile')"
            @delivery-ready-info="showDeliveryReadyInfo = true"
            @filter-all-sizes="removeFilterSize"
            @filter-size="filterSize"
            @toggle-delivery-ready="filterDeliveryReady"
            @toggle-multiple-fabrics="filterMultipleFabrics"
        />

        <CatalogFiltersDesktop
            :delivery-ready-selected="deliveryReadySelected"
            :multiple-fabrics-selected="multipleFabricsSelected"
            :show-delivery-ready-warning="showDeliveryReadyWarning"
            @delivery-ready-info="showDeliveryReadyInfo = true"
            @filter-all-sizes="removeFilterSize"
            @filter-size="filterSize"
            @toggle-delivery-ready="filterDeliveryReady"
            @toggle-multiple-fabrics="filterMultipleFabrics"
        />

        <DeliverReadyInfoModal v-model="showDeliveryReadyInfo" />
    </div>
</template>

<script>
    import { readUrlQuery } from '../../common/common';
    import DeliverReadyInfoModal from '../product/DeliverReadyInfoModal';
    import CatalogFiltersDesktop from './CatalogFiltersDesktop';
    import { catalogStore } from '../../common/store/catalog-store';
    import CatalogFiltersMobile from './CatalogFiltersMobile';

    export default {
        name: 'CatalogFiltersWrapper',

        components: {
            CatalogFiltersMobile,
            CatalogFiltersDesktop,
            DeliverReadyInfoModal,
        },

        props: {
            showMobile: Boolean,
        },

        data() {
            const stockQuery = readUrlQuery('inPhysicalStock');
            const fabricsQuery = readUrlQuery('multipleFabrics');

            return {
                storeState: catalogStore.state,
                deliveryReadySelected: stockQuery && stockQuery == 1,
                multipleFabricsSelected: fabricsQuery && fabricsQuery == 1,
                showDeliveryReadyInfo: false,
            };
        },

        computed: {
            filters() {
                return this.storeState.filters;
            },

            selected() {
                return this.storeState.selected;
            },

            deliveryReadyFilter() {
                return this.filters && this.filters.stock_filter
                    ? this.filters.stock_filter.find((f) => f.id === 1)
                    : null;
            },

            showDeliveryReadyWarning() {
                return this.deliveryReadySelected && !this.selected.size;
            },
        },

        watch: {
            selected() {
                if (this.deliveryReadyFilter) {
                    this.deliveryReadySelected =
                        this.deliveryReadySelected ||
                        this.selected.inPhysicalStock === this.deliveryReadyFilter.id;
                }
            },
        },

        methods: {
            filterSize(size, url) {
                window.dataLayer.push({
                    event: 'clickOnCatalogFilter',
                });

                if (this.$scope.IS_REGULAR_CUSTOMER) {
                    if (
                        this.selected.size === size.name &&
                        this.deliveryReadyFilter.id === this.selected.inPhysicalStock
                    ) {
                        url = catalogStore.buildUrl('inPhysicalStock', null, null, true, url);
                        this.deliveryReadySelected = false;
                    }

                    if (this.selected.size !== size.name && this.deliveryReadySelected) {
                        url = catalogStore.buildUrl(
                            'inPhysicalStock',
                            this.deliveryReadyFilter.id,
                            null,
                            false,
                            url
                        );
                    }
                }

                catalogStore.fetch(this.axios, url);
            },

            removeFilterSize(url) {
                window.dataLayer.push({
                    event: 'clickOnCatalogFilter',
                });

                if (
                    this.$scope.IS_REGULAR_CUSTOMER &&
                    this.deliveryReadyFilter.id === this.selected.inPhysicalStock
                ) {
                    url = catalogStore.buildUrl('inPhysicalStock', null, null, true, url);
                    this.deliveryReadySelected = false;
                }

                catalogStore.fetch(this.axios, url);
            },

            filterDeliveryReady() {
                window.dataLayer.push({
                    event: 'clickOnCatalogFilter',
                });

                this.deliveryReadySelected = !this.deliveryReadySelected;

                if (this.selected.size) {
                    let url = catalogStore.buildUrl(
                        'inPhysicalStock',
                        this.deliveryReadyFilter.id,
                        null,
                        this.selected.inPhysicalStock === this.deliveryReadyFilter.id
                    );
                    catalogStore.fetch(this.axios, url);
                }
            },

            filterMultipleFabrics() {
                window.dataLayer.push({
                    event: 'clickOnCatalogFilter',
                });

                this.multipleFabricsSelected = !this.multipleFabricsSelected;

                let url = catalogStore.buildUrl(
                    'multipleFabrics',
                    this.multipleFabricsSelected ? 1 : 0,
                    null,
                    !this.multipleFabricsSelected
                );
                url = catalogStore.buildUrl('cor', null, null, true, url);
                catalogStore.fetch(this.axios, url);
            },
        },
    };
</script>