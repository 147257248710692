<template>
    <div class="pos-relative bg-default-1 rounded px-1 py-1">
        <div class="d-flex flex-align-center">
            <div class="mx-2">
                {{ label }}
            </div>
            <div
                ref="popoverTrigger"
                :class="{ active: !!value }"
                class="catalog-filter-group-btn px-1 py-1 rounded d-flex cursor-pointer"
                @click="open = true"
            >
                <div class="mx-1">
                    {{ valueLabel }}
                </div>
                <i class="icon-cr icon-chevron-down" />
            </div>
        </div>

        <div
            v-show="open"
            ref="popover"
            class="pos-absolute pos-left pos-top px-1 py-1 z-3"
            style="min-width: 100%"
        >
            <div class="bg-white rounded shadow-1 px-2 py-1">
                <div class="d-flex">
                    <div>{{ label }}</div>
                    <i class="icon-cr icon-close ml-auto cursor-pointer" @click="open = false" />
                </div>
                <hr class="my-2" />
                <div class="overflow-auto" style="max-height: 35rem">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CatalogFilterGroup from './CatalogFilterGroup';

    export default {
        name: 'CatalogFilterGroupDesktop',

        extends: CatalogFilterGroup,

        data() {
            return {
                open: false,
            };
        },

        mounted() {
            window.addEventListener('click', this.onWindowClick);
        },

        beforeDestroy() {
            window.removeEventListener('click', this.onWindowClick);
        },

        methods: {
            onWindowClick(event) {
                if (
                    !this.$refs.popover.contains(event.target) &&
                    !this.$refs.popoverTrigger.contains(event.target)
                ) {
                    this.open = false;
                }
            },
        },
    };
</script>
