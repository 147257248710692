<script>
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogSort',

        data() {
            return {
                storeState: catalogStore.state,
                sortOptions: [
                    { label: this.$t('_catalog._sort.default'), value: null },
                    {
                        label: this.$t('_catalog._sort.lowestPrice'),
                        value: 'menor-preco',
                    },
                    {
                        label: this.$t('_catalog._sort.highestPrice'),
                        value: 'maior-preco',
                    },
                    {
                        label: this.$t('_catalog._sort.releases'),
                        value: 'lancamentos',
                    },
                ],
            };
        },

        computed: {
            selected() {
                return this.storeState.selected;
            },

            selectedSort() {
                const sort = this.sortOptions.find((o) => this.selected.sort === o.value);

                return sort ? sort : this.sortOptions[0];
            },
        },

        methods: {
            sort(value) {
                let url = new URL(window.location.href);

                // Reseta página
                url.searchParams.delete('page');

                if (value) url.searchParams.set('sort', value);
                else url.searchParams.delete('sort');

                catalogStore.fetch(this.axios, url.toString());
            },
        },
    };
</script>