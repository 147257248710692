<template>
    <div
        v-if="!noFiltersToDisplay"
        :class="{ busy: loading }"
        class="frame-outline px-1 py-1 hidden-xs-down"
    >
        <div class="d-flex flex-wrap flex-justify-center">
            <CatalogFilterGroupDesktop
                v-if="!$scope.IS_REGULAR_CUSTOMER"
                :value="selected.inPhysicalStock"
                class="mr my-1"
                :label="$t('productionType')"
            >
                <CatalogFilterGroupOption
                    v-for="stock in filters.stock_filter"
                    :key="stock.id"
                    :label="stock.name"
                    :value="stock.id"
                    url-param="inPhysicalStock"
                    id-prefix="desktop"
                />
            </CatalogFilterGroupDesktop>

            <CatalogFilterGroupDesktop
                v-if="displayFilterTypes"
                :value="selectedTypeId"
                class="mr my-1"
                :label="$t('type')"
            >
                <CatalogFilterGroupOption
                    v-show="selected.type_id && selected.type_id < 500"
                    :link="selected.type_url"
                    :value="undefined"
                    :label="$t('all_m')"
                    url-param="tipo"
                    id-prefix="desktop"
                />
                <CatalogFilterGroupOption
                    v-for="type in filters.types"
                    v-show="type.count > 0"
                    :key="type.id"
                    :count="type.count"
                    :label="type.name"
                    :link="type.url"
                    :value="type.id"
                    url-param="tipo"
                    id-prefix="desktop"
                />
            </CatalogFilterGroupDesktop>

            <CatalogFilterGroupDesktop
                v-if="displayFilterModels"
                :value="selected.model_url"
                class="mr my-1"
                :label="$t('model')"
            >
                <CatalogFilterGroupOption
                    :link="selected.model_url"
                    :value="undefined"
                    :label="$t('all_f')"
                    url-param="modelagem"
                    id-prefix="desktop"
                />
                <CatalogFilterGroupOption
                    v-for="model in filters.models"
                    v-show="model.count > 0"
                    :key="model.id"
                    :label="model.name"
                    :link="model.url"
                    :value="model.id"
                    url-param="modelagem"
                    id-prefix="desktop"
                />
            </CatalogFilterGroupDesktop>

            <CatalogFilterGroupDesktop
                v-if="displayFilterSizesAdult || displayFilterSizesChild || displayFilterSizes"
                :value="selected.size"
                class="mr my-1"
                :label="$t('size')"
            >
                <CatalogFilterGroupOption
                    :handle-fetch="false"
                    :value="undefined"
                    :label="$t('all_m')"
                    url-param="tamanho"
                    id-prefix="desktop"
                    @fetch="$emit('filter-all-sizes', $event)"
                />
                <div v-if="displayFilterSizesAdult">
                    <hr class="my-2" />
                    <div class="text-secondary ml-4">{{ $t('adult') }}</div>
                    <CatalogFilterGroupOption
                        v-for="size in filters.sizes_adult"
                        v-show="size.count > 0"
                        :key="size.name"
                        :handle-fetch="false"
                        :label="size.name"
                        :link="size.url"
                        :value="size.name"
                        url-param="tamanho"
                        id-prefix="desktop"
                        @fetch="$emit('filter-size', size, $event)"
                    />
                </div>
                <div v-if="displayFilterSizesChild">
                    <hr class="my-2" />
                    <div class="text-secondary ml-4">
                        {{ $t('_product.kids') }}
                    </div>
                    <CatalogFilterGroupOption
                        v-for="size in filters.sizes_child"
                        v-show="size.count > 0"
                        :key="size.name"
                        :handle-fetch="false"
                        :label="size.name"
                        :link="size.url"
                        :value="size.name"
                        url-param="tamanho"
                        id-prefix="desktop"
                        @fetch="$emit('filter-size', size, $event)"
                    />
                </div>
            </CatalogFilterGroupDesktop>

            <CatalogFilterGroupDesktop
                v-if="displayFilterColors"
                :value="selected.color"
                class="mr my-1"
                :label="$t('color')"
            >
                <CatalogFilterGroupOption
                    :link="selected.color_url"
                    :value="undefined"
                    :label="$t('all_f')"
                    url-param="cor"
                    id-prefix="desktop"
                />
                <CatalogFilterGroupOption
                    v-for="color in filters.colors"
                    v-if="color.id && color.id !== 14"
                    :key="color.id"
                    :hex="color.rgb"
                    :label="color.name"
                    :link="color.url"
                    :value="color.id"
                    url-param="cor"
                    id-prefix="desktop"
                />
            </CatalogFilterGroupDesktop>

            <div class="hidden-md-down hidden-lg-up" style="flex: 1 1 100%; height: 0" />

            <CatalogFilterGroupDesktop
                v-if="displayFilterPrices"
                :value="selected.price"
                class="mr my-1"
                :label="$t('price')"
            >
                <CatalogFilterGroupOption
                    :link="selected.price"
                    :value="undefined"
                    :label="$t('all_m')"
                    url-param="preco"
                    id-prefix="desktop"
                />
                <CatalogFilterGroupOption
                    v-for="price in filters.prices"
                    v-show="price.count > 0"
                    :key="price.id"
                    :count="price.count"
                    :label="getPriceLabel(price)"
                    :link="price.url"
                    :value="price.id"
                    url-param="preco"
                    id-prefix="desktop"
                />
            </CatalogFilterGroupDesktop>

            <CatalogFilterGroupDesktop
                v-if="displayFilterCategories"
                :fallback-value-label="selected.category_name"
                :label="$t(hasCollections ? 'category' : 'theme')"
                :value="selected.category"
                class="mr my-1"
            >
                <CatalogFilterGroupOption
                    :link="selected.category_url"
                    :value="undefined"
                    :label="$t('all_f')"
                    url-param="categoria"
                    id-prefix="desktop"
                />

                <div v-if="hasCollections">
                    <hr class="my-2" />
                    <div class="text-secondary ml-4">
                        {{ $t('exclusiveCollections') }}
                    </div>
                    <CatalogFilterGroupOption
                        v-for="collection in filters.collections"
                        v-show="collection.count > 0"
                        :key="collection.id"
                        :count="collection.count"
                        :label="collection.name"
                        :link="collection.url"
                        :value="collection.id"
                        url-param="categoria"
                        id-prefix="desktop"
                    />
                </div>

                <div v-if="hasCategories">
                    <div v-if="hasCollections">
                        <hr class="my-2" />
                        <div class="text-secondary ml-4">
                            {{ $t('themes') }}
                        </div>
                    </div>
                    <CatalogFilterGroupOption
                        v-for="category in filters.categories"
                        v-show="category.count > 0"
                        :key="category.id"
                        :count="category.count"
                        :label="category.name"
                        :link="category.url"
                        :value="category.id"
                        url-param="categoria"
                        id-prefix="desktop"
                    />
                </div>
            </CatalogFilterGroupDesktop>

            <div class="hidden-lg-down" style="flex: 1 1 100%; height: 0" />

            <template v-if="displayMulticolorFilter">
                <div
                    class="catalog-filter-check mr my-1 d-flex flex-align-center"
                    @click.stop.prevent="$emit('toggle-multiple-fabrics')"
                >
                    <div>
                        <input
                            id="catalog-filter-multiple-colors-desktop"
                            :checked="multipleFabricsSelected"
                            class="cr-checkbox"
                            type="checkbox"
                        />
                        <label for="catalog-filter-multiple-colors-desktop">
                            {{ $t('_catalog._filter.withMultipleColors') }}
                        </label>
                    </div>
                </div>

                <div
                    v-if="displayInPhysicalStock || displaySelected"
                    class="bg-default-1 mr my-2 hidden-lg-down"
                    style="flex: 0 0 2px"
                />
            </template>

            <template v-if="displayInPhysicalStock">
                <div
                    :class="{ mr: displaySelected }"
                    class="catalog-filter-check my-1 d-flex flex-align-center"
                >
                    <div class="d-flex flex-align-center">
                        <div @click.stop.prevent="$emit('toggle-delivery-ready')">
                            <input
                                id="catalog-filter-delivery-ready-desktop"
                                :checked="deliveryReadySelected"
                                class="cr-checkbox"
                                type="checkbox"
                            />
                            <label for="catalog-filter-delivery-ready-desktop">
                                {{ $t('_catalog._filter.withDeliveryReady') }}
                                <div v-show="showDeliveryReadyWarning" class="text-warning-4">
                                    {{ $t('_catalog._filter.selectSize') }}
                                </div>
                            </label>
                        </div>
                        <span @click="$emit('delivery-ready-info')">
                            <i class="cursor-pointer icon-cr icon-question-sign"></i>
                        </span>
                    </div>
                </div>

                <div
                    v-if="displaySelected"
                    class="bg-default-1 mr my-2 hidden-lg-down"
                    style="flex: 0 0 2px"
                />
            </template>

            <template v-if="displaySelected">
                <a
                    :href="mainUrl"
                    class="catalog-filter-check my-1 text-underline d-flex flex-align-center"
                    @click="resetFilters"
                >
                    <div>{{ $t('_catalog._filter.toClear') }}</div>
                </a>
            </template>
        </div>
    </div>
</template>

<script>
    import CatalogFilterGroupDesktop from './CatalogFilterGroupDesktop';
    import CatalogFilterGroupOption from './CatalogFilterGroupOption';
    import CatalogFilters from './CatalogFilters';

    export default {
        name: 'CatalogFiltersDesktop',

        extends: CatalogFilters,

        components: { CatalogFilterGroupOption, CatalogFilterGroupDesktop },
    };
</script>
