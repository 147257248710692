<template>
    <li @click="fetch">
        <label
            :class="{
                'border border-primary border-solid border-2': isSelected,
            }"
            :style="`background-color: #${hex}`"
        />
    </li>
</template>

<script>
    import CatalogFilterGroupOption from './CatalogFilterGroupOption';

    export default {
        name: 'CatalogFilterGroupOptionColor',

        extends: CatalogFilterGroupOption,
    };
</script>
