<script>
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogFilterGroup',

        provide() {
            return {
                filterGroup: {
                    radioName: () => this.radioName,
                    value: () => this.value,
                    registerOption: this.registerOption,
                },
            };
        },

        props: {
            label: String,
            value: Number | String,
            fallbackValueLabel: String,
        },

        data() {
            let radioName;

            if (this.id) {
                radioName = this.id;
            } else
                do {
                    radioName = `selectable-${Math.floor(Math.random() * 1000000)}`;
                } while (document.getElementById(radioName));

            return {
                storeState: catalogStore.state,
                radioName,
                options: [],
            };
        },

        computed: {
            loading() {
                return this.storeState.loading;
            },

            selectedOption() {
                return this.options.find((o) => (o.value || null) === (this.value || null));
            },

            valueLabel() {
                return this.selectedOption ? this.selectedOption.label : this.fallbackValueLabel;
            },
        },

        watch: {
            loading() {
                this.open = false;
            },
        },

        methods: {
            registerOption(option) {
                const { value, label } = option;

                if (this.options.filter((o) => o.value === value).length === 0) {
                    this.options.push({ value, label });

                    option.$once('hook:destroyed', () => {
                        this.unregisterOption(value);
                    });
                }
            },

            unregisterOption(value) {
                this.options = this.options.slice().filter((t) => t.value !== value);
            },
        },
    };
</script>