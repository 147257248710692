<template>
    <ul id="catalog-mobile-sort" :class="{ show }" class="hidden-xs-up text-yanone z-3">
        <li>Ordenar:</li>
        <li
            v-for="option of sortOptions"
            :class="{ 'text-bold': option.value === selected.sort }"
            @click="sort(option.value)"
        >
            {{ option.label }}
        </li>
    </ul>
</template>

<script>
    import CatalogSort from './CatalogSort';

    export default {
        name: 'CatalogSortMobile',

        extends: CatalogSort,

        props: {
            show: Boolean,
        },
    };
</script>
