<template>
    <ul
        v-if="breadcrumbs && breadcrumbs.length"
        id="catalog-breadcrumb"
        class="cr-breadcrumb"
        itemscope
        itemtype="http://schema.org/BreadcrumbList"
    >
        <li
            v-for="(breadcrumb, index) in breadcrumbs"
            itemprop="itemListElement"
            itemscope
            itemtype="http://schema.org/ListItem"
        >
            <a
                v-if="breadcrumb.url && breadcrumb.url.substring(0, 4) === 'http'"
                :href="breadcrumb.url"
                itemprop="item"
            >
                <span itemprop="name">
                    {{ breadcrumb.name }}
                </span>
            </a>
            <a
                v-else-if="breadcrumb.url"
                :href="`${$scope.URL_STORE_PREFIX}/${breadcrumb.url}`"
                itemprop="item"
            >
                <span itemprop="name">
                    {{ breadcrumb.name }}
                </span>
            </a>
            <template v-else>
                <span itemprop="name">
                    {{ breadcrumb.name }}
                </span>
            </template>

            <meta :content="index + 1" itemprop="position" />
        </li>
    </ul>
</template>

<script>
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogBreadcrumbs',

        data() {
            return {
                storeState: catalogStore.state,
            };
        },

        computed: {
            selected() {
                return this.storeState.selected;
            },

            breadcrumbs() {
                let breadcrumbs = [];

                if (this.selected.query) {
                    breadcrumbs.push({
                        name: `${this.$t('search')} "${this.selected.query}"`,
                    });
                    return breadcrumbs;
                }

                if (this.selected.tags) {
                    breadcrumbs.push({
                        name: `${this.$t('tag')} "${this.selected.tags}"`,
                    });
                    return breadcrumbs;
                }

                if (this.selected.partner) {
                    breadcrumbs.push({
                        name: `${this.$t('store')} "${this.selected.partner_name}"`,
                    });
                    return breadcrumbs;
                }

                if (this.selected.store) {
                    breadcrumbs.push({
                        name: this.selected.store.name,
                    });
                }

                if (this.selected.type_main_url && this.selected.type_main_url !== 'loja') {
                    const name =
                        this.selected.type_main_url.charAt(0).toUpperCase() +
                        this.selected.type_main_url.slice(1);
                    breadcrumbs.push({
                        name,
                        url: this.selected.type_main_url + '/',
                    });
                }

                let path = '';

                if (this.selected.type_id > 0) {
                    path = path + this.selected.type_url + '/';
                    breadcrumbs.push({
                        name: this.selected.type_name,
                        url: path,
                    });
                }
                if (this.selected.category > 0) {
                    path = path + this.selected.category_url + '/';
                    breadcrumbs.push({
                        name: this.selected.category_name,
                        url: path,
                    });
                }
                if (this.selected.model) {
                    path = path + this.selected.model_url + '/';
                    breadcrumbs.push({
                        name: this.selected.model_name,
                        url: path,
                    });
                }

                if (this.selected.color) {
                    path = path + this.selected.color_url + '/';
                    breadcrumbs.push({
                        name: this.selected.color_name,
                        url: path,
                    });
                }

                if (this.selected.size) {
                    path = path + this.selected.size_url + '/';
                    breadcrumbs.push({
                        name: this.selected.size_name,
                        url: path,
                    });
                }
                if (this.selected.price) {
                    path = path + this.selected.price_url + '/';
                    breadcrumbs.push({
                        name: this.selected.price_name,
                        url: path,
                    });
                }

                return breadcrumbs;
            },
        },
    };
</script>
