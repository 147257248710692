<template>
    <div class="text-yanone">
        <div
            v-if="options.displayTopContent && topContent && showTopContent"
            class="mb"
            v-html="topContent"
        />

        <div
            v-if="options.enableBreadcrumbs || options.enableOrdering"
            id="catalog-controls-info"
            class="d-flex flex-align-center flex-wrap mb-2"
        >
            <CatalogBreadcrumbs v-if="options.enableBreadcrumbs" />
            <div class="hidden-md-up" style="flex: 1 1 100%; height: 0" />
            <div class="catalog-desktop-sort-container d-flex flex-align-center">
                <CatalogPaginationInfo :show-sort-label="options.enableOrdering" />
                <div class="hidden-xs-down">
                    <CatalogSortDesktop v-if="options.enableOrdering" />
                </div>
            </div>
        </div>

        <CatalogFiltersWrapper
            v-if="!hideFilter && options.enableFilters && ((products || {}).hits || []).length > 0"
            :show-mobile="showMobileFilters"
            @hide-mobile="showMobileFilters = false"
        />
        <hr v-else />

        <template
            v-if="!hideFilter && options.enableFilters && ((products || {}).hits || []).length > 0"
        >
            <div
                id="catalog-overlay"
                :class="{ show: showMobileFilters || showMobileSort }"
                class="text-yanone pos-fixed pos-all z-1"
                @click="
                    showMobileFilters = false;
                    showMobileSort = false;
                "
            />

            <div class="hidden-xs-up">
                <div
                    id="catalog-mobile-toolbar"
                    :class="{ busy: loading }"
                    class="text-yanone d-flex text-center text-uppercase"
                >
                    <div class="w-1/2 px-0 py-0" @click="showMobileSort = !showMobileSort">
                        <span :class="{ 'text-info': selected.sort }">
                            <i class="icon-cr icon-sort" />
                            <span>{{ sortLabel }}</span>
                        </span>
                    </div>
                    <div class="w-1/2 px-0 py-0" @click="showMobileFilters = true">
                        <span>
                            <i class="icon-cr icon-filter"></i>
                            {{ $t('toFilter') }}
                        </span>
                    </div>
                </div>
            </div>

            <CatalogSortMobile :show="showMobileSort" />
        </template>
    </div>
</template>

<script>
    import { catalogStore } from '../../common/store/catalog-store';
    import CatalogBreadcrumbs from './CatalogBreadcrumbs';
    import CatalogSort from './CatalogSort';
    import CatalogFiltersWrapper from './CatalogFiltersWrapper';
    import CatalogSortDesktop from './CatalogSortDesktop';
    import CatalogSortMobile from './CatalogSortMobile';
    import CatalogPaginationInfo from './CatalogPaginationInfo';
    import CatalogImageOption from './CatalogImageOption';

    export default {
        name: 'CatalogControls',

        components: {
            CatalogImageOption,
            CatalogPaginationInfo,
            CatalogSortMobile,
            CatalogSortDesktop,
            CatalogFiltersWrapper,
            CatalogSort,
            CatalogBreadcrumbs,
        },

        props: {
            hideFilter: Boolean,
            showTopContent: Boolean,
        },

        data() {
            return {
                storeState: catalogStore.state,
                showMobileFilters: false,
                showMobileSort: false,
            };
        },

        computed: {
            topContent() {
                return this.storeState.topContent;
            },

            selected() {
                return this.storeState.selected;
            },

            options() {
                return this.storeState.options;
            },

            products() {
                return this.storeState.products;
            },

            loading() {
                return this.storeState.loading;
            },

            sortLabel() {
                if (this.selected.sort) {
                    if (this.selected.sort === 'menor-preco') {
                        return 'Menor preço';
                    } else if (this.selected.sort === 'maior-preco') {
                        return 'Maior preço';
                    } else if (this.selected.sort === 'lancamentos') {
                        return 'Lançamentos';
                    }
                }

                return 'Ordenar';
            },
        },

        watch: {
            loading() {
                this.showMobileSort = false;
            },
        },
    };
</script>
