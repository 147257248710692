<template>
    <div id="catalog-desktop-sort" class="d-flex">
        <div class="pos-relative bg-default-1 rounded">
            <div ref="popoverTrigger" class="cursor-pointer d-flex" @click="open = true">
                <div class="px-2">
                    {{ selectedSort.label }}
                </div>
                <i class="icon-cr icon-chevron-down" />
            </div>

            <div
                v-show="open"
                ref="popover"
                class="pos-absolute pos-right bg-white text-nowrap shadow-1 px-2 py-2 z-3"
            >
                <ul class="hidden-xs-down flex-child-fixed no-list text-right">
                    <li
                        v-for="option in sortOptions"
                        :class="{ 'text-bold': selected.sort === option.value }"
                        class="cursor-pointer py-1"
                        @click="sort(option.value)"
                    >
                        {{ option.label }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import CatalogSort from './CatalogSort';

    export default {
        name: 'CatalogSortDesktop',

        extends: CatalogSort,

        data() {
            return {
                open: false,
            };
        },

        mounted() {
            window.addEventListener('click', this.onWindowClick);
        },

        beforeDestroy() {
            window.removeEventListener('click', this.onWindowClick);
        },

        computed: {
            selected() {
                return this.storeState.selected;
            },

            loading() {
                return this.storeState.loading;
            },
        },

        watch: {
            loading() {
                this.open = false;
            },
        },

        methods: {
            onWindowClick(event) {
                if (
                    !this.$refs.popover.contains(event.target) &&
                    !this.$refs.popoverTrigger.contains(event.target)
                ) {
                    this.open = false;
                }
            },
        },
    };
</script>
