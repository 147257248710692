<script>
    import { catalogStore } from '../../common/store/catalog-store';

    export default {
        name: 'CatalogFilters',

        props: {
            deliveryReadySelected: {
                type: Boolean,
            },
            multipleFabricsSelected: {
                type: Boolean,
            },
            showDeliveryReadyWarning: {
                type: Boolean,
            },
        },

        data() {
            return {
                storeState: catalogStore.state,
            };
        },

        computed: {
            filters() {
                return this.storeState.filters;
            },

            selected() {
                return this.storeState.selected;
            },

            loading() {
                return this.storeState.loading;
            },

            selectedTypeId() {
                // considera id > 500 como null pq são os super tipos hardcoded (veja Router.php)
                return (this.selected || {}).type_id && this.selected.type_id < 500
                    ? this.selected.type_id
                    : null;
            },

            displaySelected() {
                return (
                    this.selected.type_id < 500 ||
                    this.selected.model ||
                    this.selected.category ||
                    this.selected.color ||
                    this.selected.size ||
                    this.selected.price
                );
            },

            noFiltersToDisplay() {
                return (
                    !this.displayFilterTypes &&
                    !this.displayFilterModels &&
                    !this.displayFilterSizesAdult &&
                    !this.displayFilterSizesChild &&
                    !this.displayFilterSizes &&
                    !this.displayFilterColors &&
                    !this.displayFilterCategories &&
                    !this.displayFilterPrices
                );
            },

            displayFilterTypes() {
                return (
                    this.filters.types && this.filters.types.length > 0 && !this.selected.chico_chic
                );
            },

            displayFilterModels() {
                if (!this.filters.models) return false;

                let total = this.filters.models.reduce((sum, item) => sum + item.count, 0);
                return total > 0;
            },

            displayFilterSizesAdult() {
                return this.filters.sizes_adult && this.filters.sizes_adult.length > 0;
            },

            displayFilterSizesChild() {
                return this.filters.sizes_child && this.filters.sizes_child.length > 0;
            },

            displayFilterSizes() {
                return (
                    this.filters.sizes && this.filters.sizes.length > 0 && !this.selected.chico_chic
                );
            },

            displayFilterColors() {
                return (
                    this.filters.colors &&
                    this.filters.colors.length > 0 &&
                    !this.selected.chico_chic
                );
            },

            displayMulticolorFilter() {
                return (
                    this.displayFilterColors &&
                    (!this.selected.type_main_url ||
                        this.selected.type_main_url === 'roupas' ||
                        this.selected.type_url === 'roupas')
                );
            },

            hasCategories() {
                return !(
                    !this.filters ||
                    !this.filters.categories ||
                    this.filters.categories.length === 0
                );
            },

            hasCollections() {
                return !(
                    !this.filters ||
                    !this.filters.collections ||
                    this.filters.collections.length === 0
                );
            },

            displayFilterCategories() {
                if (!this.hasCategories && !this.hasCollections) return false;

                // Não exibe se estiver filtrando produtos de parceiros
                if ((this.selected || {}).partner) return false;

                // Não exibe para o hotsite de Support Jufas Bar
                if (this.meta && (this.meta.canonical || '').indexOf('/support-jufas-bar') !== -1)
                    return false;

                // Exibe o filtro de categoria se a categoria selecionada não for a de promoção
                return !(this.selected || {}).category || (this.selected || {}).category !== 285;
            },

            displayFilterPrices() {
                return (
                    this.filters.prices &&
                    this.filters.prices.filter((price) => price.count > 0).length > 0
                );
            },

            displayInPhysicalStock() {
                return false;

                // Não exibe se estiver filtrando produtos de parceiros
                //if ((this.selected || {}).partner) return false;

                //return (
                //   this.$scope.IS_REGULAR_CUSTOMER &&
                //    (this.displayFilterSizesAdult ||
                //        this.displayFilterSizesChild ||
                //        this.displayFilterSizes)
                // );
            },

            mainUrl() {
                if (this.selected.chico_chic) return '/' + this.selected.category_url + '/';

                if (this.selected.store)
                    return window.location.origin + '/' + this.selected.store.url + '/';

                let url = this.selected.type_main_url ? this.selected.type_main_url : 'loja';

                if (this.selected.pretty_url) {
                    return window.location.origin + ('/' + url + '/');
                }

                return (
                    window.location.origin +
                    window.location.pathname.replace(/[\/]+$/, '') +
                    (this.selected.query ? '?query=' + this.selected.query : '')
                );
            },
        },

        methods: {
            getPriceLabel(price) {
                let label = '';

                if (!price.min) {
                    label += `${this.$t('until')} `;
                }
                if (price.min && price.max) {
                    label += `${this.$t('of')} `;
                }
                if (!price.max) {
                    label += `${this.$t('from')} `;
                }
                if (price.min) {
                    label += this.$f.formatMoney(price.min);
                }
                if (price.max) {
                    if (price.min) {
                        label += ` ${this.$t('until')} `;
                    }
                    label += this.$f.formatMoney(price.max);
                }

                return label;
            },

            resetFilters(event) {
                window.dataLayer.push({
                    event: 'clickOnCatalogFilter',
                });

                // Carregamento dinâmico se a API pushState estiver disponível
                if (typeof history.pushState !== 'function') return true;

                event.preventDefault();

                catalogStore.fetch(this.axios, this.mainUrl);
            },
        },
    };
</script>
