<template>
    <div>
        <div class="section-header">
            <h3 class="section-title">{{ label }}</h3>
        </div>
        <ul class="catalog-mobile-filter-list">
            <slot />
        </ul>
    </div>
</template>

<script>
    import CatalogFilterGroup from './CatalogFilterGroup';

    export default {
        name: 'CatalogFilterGroupMobile',

        extends: CatalogFilterGroup,
    };
</script>
